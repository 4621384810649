@import '../../../styles/colors';
@import '../../../styles/fonts';

.root {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: -40px;
  right: 0;
  background-color: transparentize($grey, 0.2);
}

.wrapper {
  position: relative;
  width: 500px;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  border: solid 1px $light-grey;
  background-color: $white;
  gap: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.10);
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 0px 0px #ECECEE;
  height: 40px;
  padding: 0 20px;

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  .icon {
    width: 22px;
    height: 22px;
  }
}