@import 'styles/colors';

.root {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  padding: 0 5px 0 10px;
  height: 30px;
  background: $white;
  border: 1px solid $secondary-border;
  border-radius: 5px;
}

.rowNumber {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.numberSelect {
  font-size: 14px;
  cursor: pointer;

  [class$='-control'] {
    border: 0 !important;
    box-shadow: none;
    height: 28px;
    min-height: 28px;
    width: 43px;
  }

  [class$='-menu'] {
    top: auto;
    bottom: 100%;
  }

  [class$='-ValueContainer'] {
   width: 40px;
  }

  [class$='-indicatorContainer'] {
    padding: 0;

    svg {
      width: 13px;
      height: 13px;
      margin-top: 1px;

      path {
        fill: #000;
      }
    }
  }
}

.showLabel {
  font-size: 14px;
  color: #000;
}
