@import '../../styles/colors';
@import '../../styles/fonts';

.root {
  width: 100%;
}

.form {
  width: 50%;
}

.resetDescription {
  color: $text-dark-grey;
  font-size: 16px;
  line-height: 30px;
}

.formActions {
  width: 200px;
  margin-top: 20px;
}

.submitButton {
  width: 100%;
  height: 47px;
  border-radius: 4px;
  background-color: $blue-normal;
  color: $white;
  font-size: 16px;
}

.description {
  padding: 20px;
  color: $text-dark-grey;
  font-size: 16px;
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding: 0 20px;

  button {
    width: 95px;
  }
}