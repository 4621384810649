@import 'styles/fonts';
@import 'styles/colors';

.root {
  padding: 40px 15%;
  overflow-y: auto;
  max-height: 100vh;
}

.logoHolder {
  height: 120px;
  display: flex;
  justify-content: center;
}

.heading {
  @include heading1;
  margin-bottom: 8px;
  text-align: center;
}

.subHeading {
  @include text3;
  margin-top: 0;
  color: $grey;
  text-align: center;
}

.searchInputContainer {
  width: 50%;
  margin: auto;
}

.searchInput {
  margin-top: 20px;
}

.logoImg {
  height: 100%;
}

.orgList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
  gap: 40px;
}

.orgCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 140px;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid $light-grey;
  box-shadow: 0 0 0 -15px $light-grey;
  transition: transform 0.5s, box-shadow 0.3s;
  background-color: $white;
  user-select: none;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 25px 30px -20px $light-grey;
  }
}

.orgName {
  @include heading2;
  text-align: center;
  color: $black;
  transition: all 0.3s;
  margin-bottom: 16px;

  .orgCard:hover > & {
    color: $blue-normal-hover;
  }
}

.orgType {
  @include text3;
  text-transform: capitalize;
  text-align: center;
}

.logoutButton {
  position: absolute;
  top: 12px;
  right: 80px;
}

.searchIcon {
  height: 18px;
  width: 18px;
  fill: $blue-normal;
}
