@import 'styles/colors';
@import 'styles/fonts';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  min-height: 40px;
  position: relative;
  
  .pagination {
    width: auto;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $white;
    color: $black;
    // border: 1px solid $secondary-border;
    // border-radius: 4px;
  }

  .paginationButton + .paginationButton:not(.numberButton) {
    margin-left: 5px;
  }
}

.pageNumberButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 12px;
}

.paginationButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  min-width: 28px;
  width: auto;
  padding: 0 5px;
  cursor: pointer;
  border: 0;
  background: none;
  border-radius: 4px;
  font-size: 14px;

  svg {
    path {
      fill: #000;
    }
  }

  &.active {
    background-color: $blue-normal;
    border-color: $blue-normal;
    color: $white;
    font-weight: 600;

    svg {
      path {
        fill: $grey-2;
      }
    }
  }

  &:disabled {
    color: $grey-2;
    cursor: default;
    border-color: $light-grey;

    svg {
      path {
        fill: $grey-2;
      }
    }
  }

  &:hover:not(.active):not(:disabled) {
    background-color: $light-grey;
  }

  .rightIcon {
    transform: rotate(180deg);
  }
}
